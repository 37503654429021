import React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import parse from 'html-react-parser'
import Seo from '../components/global/Seo'

export default function NewsArchive({data}) {
  return (
    <div className='blog'>
      <Seo title={`News`} description="With origins dating from 1874, the distinguished Manchester Tennis &amp; Racquet Club (MTRC) has been situated on Blackfriars Road in Salford since 1880. This truly unique members' club is one of Manchester's greatest hidden treasures."  />
      <main>
      {
        data.allWpPost.nodes.map(post => {
          return(
            <article>
              <h2>
                <Link to={post.slug}>
                  {post.title}  
                </Link>
              </h2>
              <Link to={post.slug}>
                <GatsbyImage image={post.featuredImage.node.localFile.childImageSharp.gatsbyImageData} alt={post.featuredImage.node.altText} />
              </Link>
              <div className="exceprt">{parse(post.excerpt)}</div>
              <Link to={post.slug} className={'readmore'}>Read more {`>>`} </Link>
            </article>            
          )
        })
      }
    </main>
    </div>
  )
}

export const query = graphql`

query NewsArchiveQuery {
  allWpPost(sort: {order: DESC, fields: date}) {
    nodes{
      title
      slug
      excerpt
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
}

`